import axios from "axios";
import AuthorizationService from "./AuthenticationService";
// const API_URL = 'http://localhost:8085';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

//const API_URL = "http://35.200.212.170"; // /zone/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class RecipeService {
  //to get product list-dropdown value
  getProductList(productId) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/products?orderBy=${productId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  //to get product recipe list-dropdown values
  getProductRecipeList(productId) {
    let token = AuthorizationService.getToken();
    return axios.get(
      `${API_URL}/productrecipe/api/productrecipes?productId=${productId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
  }

  //to get material category list
  getMaterialCatogoryList() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/materialmaster/api/materialcategorys/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  //to get material  list
  getMaterialList() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/materialmaster/api/materials`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  // Put Call- Add to existing Recipe button click-1st page
  updateExistingRecipe(data, recipeId) {
    let token = AuthorizationService.getToken();
    return axios.put(
      `${API_URL}/productrecipe/api/productrecipes/${recipeId}`,
      data,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
  }

  // Post Call- add material button click-2nd page
  addMaterial(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/productrecipe/api/productrecipes`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  // delete Call- Remove button(from table) click-1st/2nd page
  removeMaterial(recipeId, id) {
    let token = AuthorizationService.getToken();
    return axios.delete(
      `${API_URL}/productrecipe/api/productrecipes/${recipeId}/variant-item?variantItemId=${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
  }
}
export default new RecipeService();

import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Breadcrumb,
  Form,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { FcShipped, FcSoundRecordingCopyright } from "react-icons/fc";
import { Link } from "react-router-dom";
import "./rack.css";
import RackService from "../../../Services/RackService";

export default class RackDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      racks: [],
      barcodeString: String,
    };
  }
  componentDidMount() {
    console.log("Mount");
    RackService.getDashboardRacks().then((res) => {
      this.setState({ racks: res.data.racks || [] });
      console.log("Mount2");
      console.log(res.data);
    });
  }

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  //deallocated button click
  deallocateRacksFromTable = (e) => {
    let data = {
      rackId: "",
      barcode: this.state.barcodeString,
    };
    console.log("sampledcdcfdcfad- ", data);
    RackService.deallocateRack(data).then((res) => {
      RackService.getDashboardRacks().then((res) => {
        this.setState({ racks: res.data.racks || [] });
        console.log("Mount2");
        console.log(res.data);
      });
      this.setState({ barcodeString: "" });
    });
  };

  render() {
    let { barcodeString } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );

    return (
      <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Rack</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            Configuration
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/rack" }} linkAs={Link} >
                            Rack
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Rack Dashboard</Breadcrumb.Item>
                    </Breadcrumb>
                </div>

                <Container className='homeComponent pb-5' >                  
                    <Row className="bg-white mx-auto mb-3 pb-2"> 
                            
                      <div className='col-12 py-3'>
                        <div className="row">
                          <div className="col-4" >
                            <Form.Group controlId="barcodeString">
                              <Form.Label>Rack Barcode</Form.Label>
                              <Form.Control
                                type="text"
                                name="barcodeString"
                                onChange={this.changeHandler}
                                value={barcodeString}
                                placeholder="Scan Barcode"
                                autoComplete="off"
                              ></Form.Control>
                            </Form.Group>
                          </div>
                          <div className="col-4 d-flex align-items-end pl-0" >
                          <Form.Group>
                            <Form.Label>&nbsp;</Form.Label>
                            <Button className="" variant='gh-light' onClick={this.deallocateRacksFromTable}>Deallocate</Button> 
                          </Form.Group>
                          </div>
                        </div>
                      </div> 
                    
                        {this.state.racks.length === 0 ? (
                          <div></div>
                        ) : (
                          this.state.racks.map((rack, idx) => (
                            <Col
                              key={idx}
                              lg={3}
                              md={4}
                              sm={6}
                              xs={12}
                              className="mb-2"
                            >
                              {rack.isAllocated ? (
                                <Button variant="warning" className=" btn-block">
                                  <FcSoundRecordingCopyright /> {rack.rackName}
                                </Button>
                              ) : (
                                <Button variant="info" className=" btn-block">
                                  <FcShipped /> {rack.rackName}
                                </Button>
                              )}
                            </Col>
                          ))
                        )}

                    </Row>
                </Container>
            </div>

      </>
      
    );
  }
}

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../Images/bg-cart.png";
import ___CSS_LOADER_URL_IMPORT_1___ from "../../Images/circuit.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-container {\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat bottom right,\r\n    linear-gradient(90deg, #07351f 0%, #1d5229 24%, #07351f) no-repeat;\r\n  background-size: 35%, 100%;\r\n  height: 100vh;\r\n}\r\n.login-container::before {\r\n  content: \"\";\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat top;\r\n  background-size: 40%;\r\n  position: fixed;\r\n  height: 100%;\r\n  width: 100%;\r\n  filter: opacity(0.15) invert(1);\r\n}\r\n", "",{"version":3,"sources":["webpack://src/Components/Authentication/login.css"],"names":[],"mappings":"AAAA;EACE;sEACoE;EACpE,0BAA0B;EAC1B,aAAa;AACf;AACA;EACE,WAAW;EACX,iEAAyD;EACzD,oBAAoB;EACpB,eAAe;EACf,YAAY;EACZ,WAAW;EACX,+BAA+B;AACjC","sourcesContent":[".login-container {\r\n  background: url(\"../../Images/bg-cart.png\") no-repeat bottom right,\r\n    linear-gradient(90deg, #07351f 0%, #1d5229 24%, #07351f) no-repeat;\r\n  background-size: 35%, 100%;\r\n  height: 100vh;\r\n}\r\n.login-container::before {\r\n  content: \"\";\r\n  background: url(\"../../Images/circuit.png\") no-repeat top;\r\n  background-size: 40%;\r\n  position: fixed;\r\n  height: 100%;\r\n  width: 100%;\r\n  filter: opacity(0.15) invert(1);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

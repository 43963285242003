import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8087';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';
// const API_URL ='http://35.200.212.170'; // /opallocation/api/
const API_URL = process.env.REACT_APP_BASE_URL;
// const ZONE_API_URL = 'http://localhost:8085';
// const ZONE_API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';
// const ZONE_API_URL ='http://35.200.212.170'; // /zone/api/
const ZONE_API_URL = process.env.REACT_APP_BASE_URL;
class OperatorAllocationService {
  getAllAreaUsers(operatorType) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/opallocation/api/operatorallocations/${operatorType}-operators`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  saveOperator(operator) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/opallocation/api/operatorallocations`, operator, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }

  getAllAreas() {
    let token = AuthorizationService.getToken();
    return axios.get(`${ZONE_API_URL}/zone/api/areas/all-areas`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
}

export default new OperatorAllocationService();

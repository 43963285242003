import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8086';
// const API_URL = 'http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL = "http://35.200.212.170"; // /rack/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class RackService {
  getAllRacks(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/rack/api/racks?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getDashboardRacks() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/rack/api/racks/all-racks`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  addRack(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/rack/api/racks`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  getSingleRack(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/rack/api/racks/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateRack(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/rack/api/racks/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addRackExcel(formdata) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/rack/api/racks/upload`, formdata, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  deallocateRack(data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/rack/api/racks/deallocate`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
}
export default new RackService();

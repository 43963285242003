import React, { Component } from 'react';
import './materialInventory.css';
import {
    Row,
    Table,
    Button,
    Card,
    Col,
    Breadcrumb,
    Container,
    Spinner
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import invoiceLogo from '../../Images/logo-2.png';
import MaterialService from '../../Services/MaterialService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class MaterialInventory extends Component {
    constructor (props) {
        super(props);
        this.state = {
            materials: []
        };
    }
    componentDidMount () {
        console.log('Mount');
        MaterialService.getAllMaterialsWithoutPagination().then(res => {
            this.setState({ materials: res.data.materials ||[]});
        });
    }
    render () {

        const columns = [{
            dataField: 'id',
            text: '#',
            formatter: function(cell, row, idx){
                return idx + 1;
            }
          }, {
            dataField: 'categoryName',
            text: 'Product Category'
          }, {
            dataField: 'materialName',
            text: 'Material Name'
          }, {
            dataField: 'uom',
            text: 'UOM'
          } , {
            dataField: 'quantity',
            text: 'Quantity'
          }    
        ];
        
        return (
            <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Material Inventory Report</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/storeInventory" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Material Iventory Report</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >  

                    <Row className="bg-white mx-auto">
                        <Col>             
                            
                            <div className='mt-5 mb-3 mx-2'>
                                <Row>
                                    <Col lg={3} md={4} sm={6} xs={8}>
                                        <img
                                            src={invoiceLogo}
                                            width='100%'
                                            height='auto'
                                            className='d-inline-block align-top'
                                            alt='SG'
                                        ></img>
                                    </Col>
                                    <Col lg={9} md={8} sm={6} xs={4}>
                                        <div className="reportDate">Report Date: {new Date().toDateString()} </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className='d-flex justify-content-center mt-2 mb-2'>
 
                            <PaginationProvider
                                pagination={
                                    paginationFactory({
                                        custom: true,
                                        page: 1,
                                        sizePerPage: 10,
                                        paginationSize: 10,
                                        alwaysShowAllBtns: true,
                                        totalSize: 1 //this.state.totalItems
                                    })
                                }
                                >
                                {
                                    ({
                                    paginationProps,
                                    paginationTableProps
                                    }) => (
                                    <div className='w-100'>
                                        <BootstrapTable
                                            remote
                                            keyField="id"
                                            data={ this.state.materials }
                                            columns={ columns }                                            
                                            wrapperClasses="gh-table"
                                            // onTableChange={ handleTableChange }
                                            { ...paginationTableProps }
                                        />
                                        
                                        <div className='d-flex justify-content-center'>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                    </div>

                                    )
                                }
                            </PaginationProvider>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <NotificationContainer />
            </div>
            </>
        );
    }
}

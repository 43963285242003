import axios from 'axios';
import AuthorizationService from '../Services/AuthenticationService';
// const API_URL = 'http://localhost:8090';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

//const API_URL ='http://35.200.212.170'; // /materialmaster/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class MaterialService {
    getAllUoms(){
        let token = AuthorizationService.getToken();
        return axios.get(`${API_URL}/materialmaster/api/all-uom`, {
            headers: {
                'Authorization': `${token}`
            }
        });
    }
    getAllMaterials(pageNumber){
        let token = AuthorizationService.getToken();
        return axios.get(`${API_URL}/materialmaster/api/materials?`, {
            headers: {
                'Authorization': `${token}`
            },
            params: {
                'pageNo': `${pageNumber}`,
                'pageSize': 10
            }
        });
    }
    enable(id){
        let data='';
        let token = AuthorizationService.getToken();
        return axios.put(`${API_URL}/materialmaster/api/materials/${id}/enable`, data,{
            headers: {
                'Authorization': `${token}`
            }
        });
    }
    disable(id){
        let data='';
        let token = AuthorizationService.getToken();
        return axios.put(`${API_URL}/materialmaster/api/materials/${id}/disable`, data,{
            headers: {
                'Authorization': `${token}`
            }
        });
    }
    getSingleMaterial(id){
        let token = AuthorizationService.getToken();
        return axios.get(`${API_URL}/materialmaster/api/materials/${id}`, {
            headers: {
                'Authorization': `${token}`
            }
        });
    }
    updateMaterial(id,data){
        let token = AuthorizationService.getToken();
        return axios.put(`${API_URL}/materialmaster/api/materials/${id}`,data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            }
        });
    }
    addMaterialExcel(formdata){
        let token = AuthorizationService.getToken();
        return axios.post(`${API_URL}/materialmaster/api/materials/upload`,formdata, {
            headers: {
                'Authorization': `${token}`
            }
        });
    }
    addMaterial(data){
        let token = AuthorizationService.getToken();
        return axios.post(`${API_URL}/materialmaster/api/materials`,data,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            }
        });
    }
    getAllMaterialsWithoutPagination(){
        let token = AuthorizationService.getToken();
        return axios.get(`${API_URL}/materialmaster/api/materials/all-materials`, {
            headers: {
                'Authorization': `${token}`
            }
        });
    }
    getAllMaterialsBySupplier(supplier){
        let token = AuthorizationService.getToken();
        return axios.get(`${API_URL}/materialmaster/api/materials/mappings?`, {
            headers: {
                'Authorization': `${token}`
            },
            params: {
                'supplierId': `${supplier}`
            }
        });
    }
    addMapping(mapping){
        let token = AuthorizationService.getToken();
        return axios.post(`${API_URL}/materialmaster/api/materials/add-mapping`,mapping,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            }
        });
    }
    removeMapping(mapping){
        let token = AuthorizationService.getToken();
        return axios.post(`${API_URL}/materialmaster/api/materials/remove-mapping`,mapping,{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${token}`
            }
        });
    }
}
export default new MaterialService();
import React, { Component } from "react";
import {
  Row,
  Table,
  Col,
  Button,
  Card,
  Modal,
  Form,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { ImCancelCircle } from "react-icons/im";
import { Link } from "react-router-dom";
import "./order.css";
import CustomerOrderService from "../../Services/CustomerOrderService";
import ZoneService from "../../Services/ZoneService";
const loggedIn = sessionStorage.getItem('loggedInUser');

import AuthenticationService from '../../Services/AuthenticationService';

export default class OrderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      area: [],
      variants: [],
      cancelItem: "Number",
      data: {
        orderStatus: "pending",
      },
      checkBoxList: [],
    };
  }
  componentDidMount() {
    console.log("Mount");
    let data = this.state.data;
    let id = this.state.productId;
    CustomerOrderService.filterOrders(data)
      .then((res) => {
        this.setState({ orders: res.data || [] });
      })
      .catch((res) => {
        console.log("failure in mount filterOrders");
        console.log(res.data);
      });

    ZoneService.getAllAreasWithoutPagination()
      .then((res) => {
        this.setState({ area: res.data.areas || [] });
        console.log(res.data.areas);
      })
      .catch((res) => {
        console.log("failure in zone service");
        console.log(res.data);
      });
  }

  isSelected = (order, orderId) => {
    let orders = {
      note: order.remark,
      areaId: order.areaId,
      recipeVariantId: order.recipeVariantId,
      orderId: order.orderId,
    };
    console.log(orders);
    var checkBox = document.getElementById(orderId);
    if (checkBox.checked === true) {
      console.log("selected");
      this.setState({
        checkBoxList: this.state.checkBoxList.concat(orders),
      });
    } else {
      console.log("not Selected");
      this.setState((prevState) => ({
        checkBoxList: prevState.checkBoxList.filter(
          (el) => el.orderId !== orders.orderId
        ),
      }));
    }
    console.log(this.state.checkBoxList);
  };
  cancelOrder = () => {
    let data = this.state.data;
    let id = this.state.cancelItem;
    CustomerOrderService.cancelOrder(id)
      .then((res) => {
        console.log("success");

        CustomerOrderService.filterOrders(data)
          .then((res) => {
            this.setState({ orders: res.data });
          })
          .catch((res) => {
            console.log("failure in filterOrders");
            console.log(res.data);
          });
      })
      .catch((res) => {
        console.log("failure in cancelOrder");
        console.log(res.data);
      });
    this.closeModal();
  };
  changeHandler = (e, id) => {
    let order =
      this.state.orders &&
      this.state.orders.map((o) => {
        let orderCopy = o;
        if (o.orderId === id) {
          orderCopy = { ...o, [e.target.name]: e.target.value };
          console.log(orderCopy);
          let objIndex = this.state.checkBoxList.findIndex(
            (obj) => obj.orderId == id
          );
          if (objIndex === -1) {
            return orderCopy;
          }
          const filteredList = this.state.checkBoxList;

          const valueProp = e.target.name;
          const obj = filteredList[objIndex];
          const obj1 = {
            ...obj,
            [e.target.name === "remark" ? "note" : e.target.name]:
              e.target.value,
          };
          filteredList[objIndex] = obj1;
          this.setState({
            checkBoxList: filteredList,
          });
        }
        return orderCopy;
      });
    this.setState({ orders: order }, () => {
      console.log(this.state);
    });
  };
  deleteSet = (id) => {
    this.setState({ cancelItem: id });
    console.log(id);
  };
  openModal = () => {
    this.setState({ openModal: true });
  };

  closeModal = () => {
    this.setState({ openModal: false });
  };

  //accept order click-post call
  acceptOrder = () => {
    var orders = this.state.checkBoxList;
    console.log("Accept Order");
    console.log(orders);

    if (orders.length == 0) {
      this.setState({ openAcceptOrderCheckModal: true });
      return;
    }
    CustomerOrderService.acceptOrder(orders)
      .then((res) => {
        console.log("ggggsuccess");
        this.setState({ checkBoxList: [] });
        this.setState({ orders: [] });

        CustomerOrderService.filterOrders(this.state.data)
          .then((res) => {
            this.setState({ orders: res.data || [] });
          })
          .catch((res) => {
            console.log("failure in mount filterOrders");
            console.log(res.data);
          });
      })
      .catch((res) => {
        console.log("failure");
        console.log(res.data);
      });
  };

  onChangeForVarientDropdown = (e, id) => {
    let order =
      this.state.orders &&
      this.state.orders.map((o) => {
        let orderCopy = o;
        if (o.orderId === id) {
          orderCopy = { ...o, [e.target.name]: e.target.value };
          console.log(orderCopy);
          let objIndex = this.state.checkBoxList.findIndex(
            (obj) => obj.orderId == id
          );
          const filteredList = this.state.checkBoxList;
          if (objIndex === -1) {
            return orderCopy;
          }
          filteredList[objIndex].recipeVariantId = e?.target?.value;
          this.setState({
            checkBoxList: filteredList,
          });
        }
        return orderCopy;
      });
    this.setState({ orders: order }, () => {
      console.log(this.state);
    });
  };

  handleVarientDropdownClick = (productId) => {
    CustomerOrderService.productRecipe(productId)
      .then((res) => {
        console.log("success");
        this.setState({ variants: res.data });
      })
      .catch((res) => {
        console.log("failure in varient service");
      });
  };

  render() {
    let userRole = AuthenticationService.getRole();
    let { area, variants } = this.state;
    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );
    return (
      <>
      <div className='gh-bgcolor gh-dashboard-bgimg' >
          
          <div className='container d-flex align-items-center justify-content-between' >

              <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Order</h4>

              <Breadcrumb className='gh-breadcrumb'>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                      <FaHome />
                  </Breadcrumb.Item>
                  <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                      Order
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Order</Breadcrumb.Item>
              </Breadcrumb>
          </div>

          <Container className='homeComponent pb-5' >                  
              <Row className="bg-white mx-auto">
                  <Col>   
                      <div className='mt-4 mb-3 mx-2'>  
                          <Link to="/addOrder">
                            <Button variant="gh-light">Add Order</Button>
                          </Link>
                          {userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer' ? (
                              <Link to="/addOrderExcel">
                                <Button className="ml-3" variant="gh-light">
                                  Bulk Order
                                </Button>
                              </Link> 
                          ) : ''}
                      </div>

                      <div className='d-flex justify-content-center mt-2 mb-2 gh-table'>
                        {
                        userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer' ? (
                        <Table bordered >
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Area</th>
                              <th>Variant</th>
                              <th>Note</th>
                              <th>Banner</th>
                              <th>Store</th>
                              <th>Product</th>
                              <th>Year-Week</th>
                              <th>Quantity</th>
                              <th>Inserted Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.orders.length === 0 ? (
                              <div></div>
                            ) : (
                              this.state.orders.map((order, idx) => (
                                <tr key={idx}>
                                  <td>
                                    <Form.Check
                                      type="checkbox"
                                      id={order.orderId}
                                      value={order.orderId}
                                      onClick={(e) =>
                                        this.isSelected(order, order.orderId)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Form.Group controlId="areaId">
                                      <Form.Control
                                        as="select"
                                        name="areaId"
                                        onChange={(e) => {
                                          this.changeHandler(e, order.orderId);
                                        }}
                                        value={order.areaId}
                                      >
                                        <option>Select area</option>
                                        {area.map((areaItem, idx) => (
                                          <option key={idx} value={areaItem.areaId}>
                                            {areaItem.areaName}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group controlId="recipeVariantId">
                                      <Form.Control
                                        as="select"
                                        name="recipeVariantId"
                                        onChange={(e) =>
                                          this.onChangeForVarientDropdown(
                                            e,
                                            order.orderId
                                          )
                                        }
                                        onClick={(e) => {
                                          this.handleVarientDropdownClick(
                                            order.productId
                                          );
                                        }}
                                        value={order.recipeVariantId}
                                      >
                                        <option>Select Variant</option>
                                        {variants.map((variant, idx) => (
                                          <option key={idx} value={variant.recipeId}>
                                            {variant.variantName}
                                          </option>
                                        ))}
                                      </Form.Control>
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <Form.Group controlId="remark">
                                      <Form.Control
                                        type="text"
                                        name="remark"
                                        onChange={(e) => {
                                          this.changeHandler(e, order.orderId);
                                        }}
                                        value={order.remark}
                                        placeholder="Note"
                                        autoComplete="off"
                                      ></Form.Control>
                                    </Form.Group>
                                  </td>
                                  <td>{order.customerName}</td>
                                  <td>{order.storeName}</td>
                                  <td>{order.productName}</td>
                                  <td>
                                    {order.year}-{order.weekNumber}
                                  </td>
                                  <td>{order.quantity}</td>
                                  <td>{order.insertedDate}</td>
                                  <td>
                                    <div className="gh-tooltip top" data-content="Cancel">
                                        <div
                                            className='ml-2 gh-status-Closed'
                                            onClick={() => {
                                              this.deleteSet(order.orderId);
                                              this.openModal();
                                            }}
                                        >
                                            <ImCancelCircle size={20} />
                                        </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table> ) : (
                        <Table bordered >
                          <thead>
                            <tr>
                              <th>Banner</th>
                              <th>Store</th>
                              <th>Product</th>
                              <th>Year-Week</th>
                              <th>Quantity</th>
                              <th>Inserted Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.orders.length === 0 ? (
                              <div></div>
                            ) : (
                              this.state.orders.map((order, idx) => (
                                <tr key={idx}>
                                  <td>{order.customerName}</td>
                                  <td>{order.storeName}</td>
                                  <td>{order.productName}</td>
                                  <td>
                                    {order.year}-{order.weekNumber}
                                  </td>
                                  <td>{order.quantity}</td>
                                  <td>{order.insertedDate}</td>
                                  <td> 

                                    <div className="gh-tooltip top" data-content="Cancel">
                                        <div
                                            className='ml-2 gh-status-Closed'
                                            onClick={() => {
                                              this.deleteSet(order.orderId);
                                              this.openModal();
                                            }}
                                        >
                                            <ImCancelCircle size={20} />
                                        </div>
                                    </div>


                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </Table>
                        )}
                      </div>

                      <div className='mb-3'>
                        {userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer' ? (
                        <Row>
                          <Col className="ml-3 mb-2">
                            <Button
                              variant="success"
                              onClick={() => {
                                this.acceptOrder();
                              }}
                            >
                              Accept Orders
                            </Button>
                          </Col>
                        </Row>
                        ) : ('')}
                      </div>

                  </Col>
              </Row>
          </Container>

          <Modal
            className="textCenter"
            show={this.state.openModal}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>Cancel Orders</Modal.Header>
            <Modal.Body>Do you really want to cancel?</Modal.Body>
            <Modal.Footer> 
              <Button className='mt-3' onClick={this.closeModal} variant='gh-light'>
              Close
              </Button> 
              <Button
                  className='mt-3 ml-3'
                  variant='gh-success' 
                  onClick={ this.cancelOrder }
              >
              Cancel
              </Button>

            </Modal.Footer>
          </Modal>

          <Modal
            className="textCenter"
            show={this.state.openAcceptOrderCheckModal}
            onHide={this.closeModal}
          >
            <Modal.Header closeButton>Accept Order</Modal.Header>
            <Modal.Body>Please select atleast one order</Modal.Body>
            <Modal.Footer>
              <Button
                variant="dark"
                onClick={() => {
                  this.setState({ openAcceptOrderCheckModal: false });
                }}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
      </div>

      </>
    );
  }
}

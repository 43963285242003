import React, { Component } from 'react';
import {
    Row,
    Table,
    Card,
    Container
} from 'react-bootstrap';
import CustomerOrderService from '../../Services/CustomerOrderService';
import Pagination from 'react-js-pagination';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

export default class DispatchedOrders extends Component {
    constructor (props) {
        super(props);
        this.state = {
            dispatches: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        CustomerOrderService.getAllDispatch(page).then(res => {

            res.data.dispatches.map((item, idx)=>{
                item.id = idx + 1 + page * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ dispatches: res.data.dispatches ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        CustomerOrderService.getAllDispatch(activePage).then(res => {

            res.data.dispatches.map((item, idx)=>{
                item.id = idx + 1 + activePage * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ dispatches: res.data.dispatches ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    render() {

        const handleTableChange = (type, { page, sizePerPage }) => {
            this.handlePageChange (page);
        }
        
        const columns = [{
            dataField: 'id',
            text: '#',
          }, {
            dataField: 'invoiceNumber',
            text: 'Invoice Number'
          }, {
            dataField: 'customerName',
            text: 'Banner Detail',
            formatter: function(cell, row){
                return 'Banner Name: '+ cell;
            }
          }, {
            dataField: 'zoneDetail',
            text: 'Zone Detail',
            formatter: function(cell, row){
                return 'Store Name: '+ cell;        // storeName
            }
          }, {
            dataField: 'totalItems',
            text: 'Total Items', 
          }, {
            dataField: 'invoiceDate',
            text: 'Invoice Data'
          }, {
            dataField: 'dispatchStatus',
            text: 'Dispatch Status'
          }   
        ];

        return (
            <Container className='mainDispatch'>
                <Row>
                    <Card className='customerCard'>

                        <PaginationProvider
                            pagination={
                                paginationFactory({
                                    custom: true,
                                    page: this.state.currentPage + 1,
                                    sizePerPage: 10,
                                    paginationSize: 10,
                                    alwaysShowAllBtns: true,
                                    totalSize: this.state.totalItems
                                })
                            }
                            >
                            {
                                ({
                                paginationProps,
                                paginationTableProps
                                }) => (
                                <div className='w-100'>
                                    <BootstrapTable
                                        remote
                                        keyField="id"
                                        data={ this.state.dispatches }
                                        columns={ columns }                                            
                                        wrapperClasses="gh-table"
                                        onTableChange={ handleTableChange }
                                        { ...paginationTableProps }
                                    />
                                    
                                    <div className='d-flex justify-content-center'>
                                        <PaginationListStandalone
                                            { ...paginationProps }
                                        />
                                    </div>
                                    {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                                    
                                </div>

                                )
                            }
                        </PaginationProvider>
                    </Card>
                </Row>
            </Container>
        );
    }
}

import React, { Component } from 'react';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    Modal,
    OverlayTrigger,
    Tooltip,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import './zone.css';
import ZoneService from '../../../Services/ZoneService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class ZoneComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            zones: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null,
            openModal: false
        };
    }
  openModal = () => {
      this.setState({ openModal: true });
  }
  closeModal = () => {
      this.setState({ openModal: false });
  }
  handlePageChange (pageNumber) {
      let page = pageNumber - 1;
      this.setState({ currentPage: page });
      ZoneService.getAllZones(page).then(res => {
          this.setState({ zones: res.data.zones ||[]});
          const totalPages = res.data.totalPages;
          const totalItems = res.data.totalItems;

          this.setState({ totalPages: totalPages });
          this.setState({ totalItems: totalItems });
          console.log('Mount2');
          console.log(res.data);
      });
  }

  componentDidMount () {
      console.log('Mount');
      let activePage = this.state.currentPage;
      ZoneService.getAllZones(activePage).then(res => {
          this.setState({ zones: res.data.zones ||[]});
          const totalPages = res.data.totalPages;
          const totalItems = res.data.totalItems;

          this.setState({ totalPages: totalPages });
          this.setState({ totalItems: totalItems });
          console.log('Mount2');
          console.log(res.data);
      });
  }
  render () {
        let { currentPage } = this.state;
        let that = this;
      
        const handleTableChange = (type, { page, sizePerPage }) => {
            this.handlePageChange (page);
        }

        // const openModal = () => {
        //     alert('open')
        //     //this.openModal();
        // }
        // const closeModal = () => {
        //     alert('close')
        //     //this.closeModal();
        // }

        const columns = [{
            dataField: 'zoneId',
            text: '#',
        }, {
            dataField: 'zoneName',
            text: 'Zone Name'
        }, {
            dataField: 'zoneType',
            text: 'Zone Type'
        }, {
            dataField: 'capacity',
            text: 'Capacity(Sq.Ft/Count)'
        }, {
            dataField: 'areaName',
            text: 'Area', 
        }, {
            dataField: 'insertedDate',
            text: 'Inserted Date'
        }, {
            dataField: 'Action',
            isDummyField: true,
            text: 'Action',
            formatter: function(cell, row){
                return (
                    <>
                    <div className='d-flex'>
                        <div className="gh-tooltip top" data-content="Edit">
                            <Link
                                to={{
                                    pathname: '/updateZone',
                                    state: { id: row.zoneId }
                                }}
                            ><BiEdit size={20} className="gh-status-Closed" />
                            </Link>
                        </div>
                        <div className="gh-tooltip top" data-content="Disable">
                            <div
                                className='ml-2 gh-status-Pending'
                                onClick={that.openModal}
                            >
                                <RiDeleteBin6Line size={20}/>
                            </div>
                        </div>
                    </div>                   
                    </>
                );
            }
        }     
        ];

      return (
        <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Zone</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            Configuration
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Zone</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >                  
                    <Row className="bg-white mx-auto">
                        <Col>             
                            
                            <div className='mt-5 mb-3 mx-2'>
                                <Link to='/addZone'>
                                    <Button className='mr-3' variant='gh-light'>
                                        Add Zone
                                    </Button>
                                </Link>
                            </div>

                            <div className='d-flex justify-content-center mt-2 mb-2'>
 
                            <PaginationProvider
                                pagination={
                                    paginationFactory({
                                        custom: true,
                                        page: this.state.currentPage + 1,
                                        sizePerPage: 10,
                                        paginationSize: 10,
                                        alwaysShowAllBtns: true,
                                        totalSize: this.state.totalItems
                                    })
                                }
                                >
                                {
                                    ({
                                    paginationProps,
                                    paginationTableProps
                                    }) => (
                                    <div className='w-100'>
                                        <BootstrapTable
                                            remote
                                            keyField="zoneId"
                                            data={ this.state.zones }
                                            columns={ columns }                                            
                                            wrapperClasses="gh-table"
                                            onTableChange={ handleTableChange }
                                            { ...paginationTableProps }
                                        />
                                        
                                        <div className='d-flex justify-content-center'>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                                        
                                    </div>

                                    )
                                }
                            </PaginationProvider>

                            </div>

                            <Modal
                              className='textCenter'
                              show={this.state.openModal}
                              onHide={this.closeModal}
                            >
                              <Modal.Header closeButton>Delete</Modal.Header>
                              <Modal.Body>Do you really want to delete?</Modal.Body>
                              <Modal.Footer>
                                  <Button variant='danger' onClick={this.closeModal}>
                                    Delete
                                  </Button>
                              </Modal.Footer>
                            </Modal>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <NotificationContainer />
            </div>
        </>
      );
  }
}

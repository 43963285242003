import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8091';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL = 'http://35.200.212.170'; ///materialorder/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class MaterialOrder {
  getAllOpenStore(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/materialorder/api/storeorders?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
        status: "OPEN",
      },
    });
  }
  getAllPurchases(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/materialorder/api/purchaseorders?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
        status: "OPEN",
      },
    });
  }
  getAllClosedStore(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/materialorder/api/storeorders?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
        status: "CLOSED",
      },
    });
  }
  addStoreOrder(order) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/materialorder/api/storeorders`, order, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  approveStoreOrder(order, id) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/materialorder/api/storeorders/${id}/approve`, order, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  rejectStoreOrder(order, id) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/materialorder/api/storeorders/${id}/reject`, order, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  preOrderDetail() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/materialorder/api/purchaseorders/preorder-detail`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  purchaseOrder(order) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/materialorder/api/purchaseorders`, order, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updatePurchaseOrder(id, order) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/materialorder/api/purchaseorders/${id}`, order, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  deletePurchaseOrder(id) {
    let token = AuthorizationService.getToken();
    return axios.delete(`${API_URL}/materialorder/api/purchaseorders/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getSinglePurchaseOrder(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/materialorder/api/purchaseorders/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  payBill(id, bill) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/materialorder/api/purchaseorders/${id}/invoice-details`, bill, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
}
export default new MaterialOrder();

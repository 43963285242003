import React, { Component } from 'react';
import './store.css';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Form,
    Tooltip,
    OverlayTrigger,
    Container,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdDone } from 'react-icons/md';
import { Link } from 'react-router-dom';
import customerService from '../../../Services/CustomerService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class StoreComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stores: [],
            corporates: [],
            customerId:Number,
            customers: [],
            addresses: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        customerService.getAllStores(page).then(res => {

            res.data.stores.map((item, idx)=>{
                item.id = idx + 1 + activePage * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ stores: res.data.stores ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    enable (id) {
        customerService.enableStore(id).then(res => {
            console.log(res.data);
            let page = this.state.currentPage;
            NotificationManager.success('Banner has been enabled now', 'Enabled');
            customerService.getAllStores(page).then(res => {
                this.setState({ stores: res.data.stores ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    disable (id) {
        customerService.disableStore(id).then(res => {
            console.log(res.data);
            NotificationManager.warning('Banner has been disabled now', 'Disabled');
            let page = this.state.currentPage;
            customerService.getAllStores(page).then(res => {
                this.setState({ stores: res.data.stores ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            });
        });
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        customerService.getAllStores(activePage).then(res => {

            res.data.stores.map((item, idx)=>{
                item.id = idx + 1 + activePage * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ stores: res.data.stores ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
        customerService.getAllCorporates().then(res => {
            this.setState({ corporates: res.data.corporates||[] });
        });
    }
    changeHandler = e => {
        switch (e.target.name) {
        case 'corporateName':
            let name = e.target.value;
            console.log(name);
            customerService.getCustomerbyCorporateName(name).then(res => {
                this.setState({ customers: res.data.customers });
            });
            break;
        default:
            break;
        }
        this.setState({ [e.target.name]: e.target.value }, () => {
            console.log(this.state);
        });
    }
    filterStore = e => {
        e.preventDefault();
        let activePage = this.state.currentPage;
        let id=this.state.customerId;
        customerService
            .getFilteredStores(activePage,id)
            .then(res => {
                this.setState({ stores: res.data.stores ||[]});
                console.log('Mount2');
                console.log(res.data);
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;

                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
            })
            .catch(res => {
                console.log('failure');
                console.log(res.data);
            });
    }

  render () {

      let {
          currentPage,
          corporateName,
          corporates,
          customerId,
          customers
      } = this.state;

        const handleTableChange = (type, { page, sizePerPage }) => {
            this.handlePageChange (page);
        }

        const disable = (id) => {
            this.disable(id);
        }

        const enable = (id) => {
            this.enable(id);
        }

        const columns = [{
                dataField: 'id',
                text: '#', 
            }, {
                dataField: 'corporateName',
                text: 'Corporate Name'
            }, {
                dataField: 'customerName',
                text: 'Banner Name'
            },{
                dataField: 'storeName',
                text: 'Store Name'
            },{
                dataField: 'storeNumber',
                text: 'Store Number'
            },
            {
                dataField: 'emailAddress',
                text: 'Email'
            }, {
                dataField: 'phoneNumber',
                text: 'Phone', 
            }, 
            {
                dataField: 'addresses[0].address1',
                text: 'Address', 
            },      
            {
                dataField: 'insertedDate',
                text: 'Inserted Date'
            }, {
            dataField: 'Action',
            isDummyField: true,
            text: 'Action',
        formatter: function(cell, row){
            return (
                <>
                    <div className='d-flex'>
                        <div className="gh-tooltip top" data-content="Edit">
                            <Link
                                to={{
                                    pathname: '/updateStore',
                                    state: { id: row.storeId }
                                }}
                            ><BiEdit size={20} className="gh-status-Closed" />
                            </Link>
                        </div>

                        {row.isDeleted ? (
                            <div className="gh-tooltip top" data-content="Enable">
                                <div
                                    className='ml-2 gh-status-Closed'
                                    onClick={() => {
                                        enable(row.storeId);
                                    }}
                                >
                                    <MdDone size={20}/>
                                </div>
                            </div>
                        ) : (
                            <div className="gh-tooltip top" data-content="Disable">
                                <div
                                    className='ml-2 gh-status-Pending'
                                    onClick={() => {
                                        disable(row.storeId);
                                    }}
                                >
                                    <RiDeleteBin6Line size={20}/>
                                </div>
                            </div>
                        )} 
                    </div>                   
                </>
            );
        }
        }];
      
      return (
        <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Stores</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            Profiles
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Store</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >                  
                    <Row className="bg-white mx-auto">
                        <Col>

                            <div className='mt-4 mb-3 mx-2'>

                                <Form>
                                    <Row>
                                        <Col lg={4} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='corporateName'>
                                                <Form.Label>Corporate Name </Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    name='corporateName'
                                                    onChange={this.changeHandler}
                                                    value={corporateName}
                                                >
                                                    <option>Select Corporate</option>
                                                    {corporates.map((corporate, idx) => (
                                                        <option key={idx} value={corporate.corporateName}>
                                                            {corporate.corporateName}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='customerId'>
                                                <Form.Label>Banner Name </Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    name='customerId'
                                                    onChange={this.changeHandler}
                                                    value={customerId}
                                                >
                                                    <option>Select Banners</option>
                                                    {customers.map((customer, idx) => (
                                                        <option key={idx} value={customer.customerId}>
                                                            {customer.customerName}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} md={6} sm={6} xs={12}>
                                            <Button
                                                className=' ml-5 downButtonStore'
                                                variant='gh-success'
                                                type='submit'
                                                onClick={this.filterStore}
                                            >
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form> 
                                
                                <Link to='/addStore'>
                                    <Button className='mr-3' variant='gh-light'>
                                        Add Store
                                    </Button>
                                </Link>

                            </div>

                            <div className='d-flex justify-content-center mt-2 mb-2'>
 
                            <PaginationProvider
                                pagination={
                                    paginationFactory({
                                        custom: true,
                                        page: this.state.currentPage + 1,
                                        sizePerPage: 10,
                                        paginationSize: 10,
                                        alwaysShowAllBtns: true,
                                        totalSize: this.state.totalItems
                                    })
                                }
                                >
                                {
                                    ({
                                    paginationProps,
                                    paginationTableProps
                                    }) => (
                                    <div>
                                        <BootstrapTable
                                            remote
                                            keyField="id"
                                            data={ this.state.stores }
                                            columns={ columns }                                            
                                            wrapperClasses="gh-table"
                                            onTableChange={ handleTableChange }
                                            { ...paginationTableProps }
                                        />
                                        
                                        <div className='d-flex justify-content-center'>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                       
                                    </div>

                                    )
                                }
                            </PaginationProvider>

                             {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>

          <div>
              <NotificationContainer />
          </div>
          
        </>
      );
  }
}

import React, { Component } from 'react';
import {
    Row,
    Col,
    Tabs,
    Tab,
    Container,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './dispatch.css';
import DispatchedOrders from './DispatchedOrders';
import NonDipatchedOrders from './NonDipatchedOrders';
import AuthenticationService from '../../Services/AuthenticationService';
export default class DipatchComponent extends Component {
    
    render () {
        let userRole = AuthenticationService.getRole();
        const ColoredLine = ({ color }) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    marginTop: 0,
                    height: 1
                }}
            />
        );
        return (
            <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Dispatch</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Dispatch</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >                  
                    <Row className="bg-white mx-auto">
                        <Col className='py-3'>             
                            
                            {userRole=='ROLE_Administrator' &&(
                    <Tabs defaultActiveKey="dispatchedOrder" 
                        id="controlled-tab-example">
                        <Tab eventKey="dispatchedOrder" title="Dispatched Order">
                            <DispatchedOrders/> 
                        </Tab>
                        <Tab eventKey="nonDispatchedOrder" title="Non Dispatched Order">
                            <NonDipatchedOrders/>
                        </Tab>
                    </Tabs>
                            )}
                            {userRole=='ROLE_Operator' &&(
                                <NonDipatchedOrders/>
                            )}

                        </Col>
                    </Row>
                </Container> 

            </div>
            </>
        );
    }
}

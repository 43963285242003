import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Button,
    Row,
    Col,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './rack.css';
import RackService from '../../../Services/RackService';

export default class UpdateRack extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            rack: {
                rackName: String,
                barcode: String
            }
        };
    }
    componentDidMount () {
        let id = this.state.id;
        RackService.getSingleRack(id).then(res => {
            this.setState({ rack: res.data ||[]});
        });
    }

  changeHandler = e => {
      let rack = this.state.rack;
      rack[e.target.name] = e.target.value;
      this.setState({ rack }, () => console.log(this.state.rack));
  }

  updateRack = e => {
      e.preventDefault();
      let id = this.state.id;
      let rack = this.state.rack;
      RackService
          .updateRack(id, rack)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/rack');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { rackName, barcode} = this.state.rack;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
        <>
        <div className='gh-bgcolor gh-dashboard-bgimg' >
            
            <div className='container d-flex align-items-center justify-content-between' >

                <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Rack</h4>

                <Breadcrumb className='gh-breadcrumb'>
                    <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                        <FaHome />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                        Configuration
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkProps={{ to: "/rack" }} linkAs={Link}>
                        Rack
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Update Rack</Breadcrumb.Item>
                </Breadcrumb>

            </div>

            <Container className='homeComponent pb-5' >
                <Card>
                    <Card.Header className='px-5 gh-card-header'>
                        <Card.Title className='card-title mb-0 text-left'>
                            <h4 className="mb-0">Update Rack</h4>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className='p-5'>
                    <Form>
                        <Row>
                            <Col lg={4} md={6} sm={6} xs={12}>
                                <Form.Group controlId='rackName'>
                                    <Form.Label>Rack Name </Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='rackName'
                                        onChange={this.changeHandler}
                                        value={rackName}
                                        placeholder='Rack Name'
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={6} sm={6} xs={12}>
                                <Form.Group controlId='barcode'>
                                    <Form.Label>Barcode </Form.Label>
                                    <Form.Control
                                        type='text'
                                        name='barcode'
                                        onChange={this.changeHandler}
                                        value={barcode}
                                        placeholder='Barcode'
                                    ></Form.Control>
                                </Form.Group>
                              </Col>
                        </Row>
                        <Row className=''>
                            <Col className='text-right'>
                            <Link to='/rack'>
                                <Button className='mt-3' variant='gh-light'>
                                    Back
                                </Button>
                            </Link>
                            <Button
                                className='mt-3 ml-3'
                                variant='gh-success'
                                type='submit'
                                onClick={this.updateRack}
                            >
                                Submit
                            </Button>
                            </Col>
                        </Row>
                    </Form>
                    </Card.Body>
                </Card>
            </Container>
          </div>
        </>
      );
  }
}

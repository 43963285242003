import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8085';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL ='http://35.200.212.170'; // /zone/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class ZoneService {
  getAllZones(pageNumber) {
    let token = AuthorizationService.getToken();
    console.log("zones");
    console.log(token);
    console.log("Inside zone call");
    return axios.get(`${API_URL}/zone/api/zones?`, {
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllAreas(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/zone/api/areas?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getAllAreasWithoutPagination() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/zone/api/areas/all-areas`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllZonesWithoutPagination() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/zone/api/zones/all-zones`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getSingleArea(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/zone/api/areas/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateArea(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/zone/api/areas/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addArea(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/zone/api/areas`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  getAllZoneType() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/zone/api/zonetypes`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getSingleZone(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/zone/api/zones/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateZone(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/zone/api/zones/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }

  addZone(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/zone/api/zones`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
}
export default new ZoneService();

import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8088';
// const API_URL ='http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL ='http://35.200.212.170'; // /product/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class ProductService {
  getAllProducts(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/products?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
        Authorization: `${token}`,
      },
    });
  }
  getAllCategories(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/categories?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  enable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/product/api/products/${id}/enable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  disable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/product/api/products/${id}/disable`, data, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllCategoriesWithoutPagination() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/categories/all-categories`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getAllSubCategories(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/subcategories?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getAllSubCategoriesByCategory(categoryId) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/subcategories/by-categoryid?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        categoryId: `${categoryId}`,
      },
    });
  }
  getAllProductsBySubCategories(subCategoryId) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/products/by-subcategoryid?`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        subCategoryId: `${subCategoryId}`,
      },
    });
  }
  getSingleCategory(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/categories/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  getSingleSubCategory(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/subcategories/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateCategory(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/product/api/categories/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  updateSubCategory(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/product/api/subcategories/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addProductExcel(formdata) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/product/api/products/upload`, formdata, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  addProduct(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/product/api/products`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addCategory(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/product/api/categories`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addSubCategory(data) {
    let token = AuthorizationService.getToken();
    return axios.post(`${API_URL}/product/api/subcategories`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  getSingleProduct(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/product/api/products/${id}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  updateProduct(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/product/api/products/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
}
export default new ProductService();

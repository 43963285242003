import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthenticationService from '../../Services/AuthenticationService';

class LogoutComponent extends Component {

    render() {
        AuthenticationService.logout();
        // eslint-disable-next-line react/prop-types
        this.props.history.push('/');
        return (
            null
        );
    }
}

export default withRouter(LogoutComponent);
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Table,
    Col,
    Button,
    Card,
    Container,
    Tooltip,
    Modal,
    Form,
    OverlayTrigger,
    Breadcrumb
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { BiEdit } from 'react-icons/bi';
import { MdDeleteForever } from 'react-icons/md';
import { RiBillFill } from 'react-icons/ri';
import { MdDone } from 'react-icons/md';
import { RiDeleteBin6Line } from "react-icons/ri";
import Pagination from 'react-js-pagination';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import { NotificationContainer, NotificationManager } from 'react-notifications';

import MaterialOrder from '../../../Services/MaterialOrder';
import './purchase.css';
export default class PurchaseOrder extends Component {
    constructor (props) {
        super(props);
        this.state = {
            id:String,
            invoiceNumber:String,
            invoiceDate:Date,
            totalCost:Number,
            purchaseOrders: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        console.log(activePage);
        MaterialOrder.getAllPurchases(activePage).then(res => {
            res.data.purchaseOrders.map((item, idx)=>{
                item.id = idx + 1 + activePage * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    delete=e=>{
        e.preventDefault();
        let activePage = this.state.currentPage;
        MaterialOrder.deletePurchaseOrder(this.state.id).then(res => {
            this.closeModal();
            MaterialOrder.getAllPurchases(activePage).then(res => {
                res.data.purchaseOrders.map((item, idx)=>{
                    item.id = idx + 1 + activePage * 10;
                    return item;
                });
    
                return res;
    
            }).then(res => {
                this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;
    
                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        MaterialOrder.getAllPurchases(activePage).then(res => {
            res.data.purchaseOrders.map((item, idx)=>{
                item.id = idx + 1 + activePage * 10;
                return item;
            });

            return res;

        }).then(res => {
            this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
            console.log('Mount2');
            console.log(res.data);
        });
    }
    openModal = (id) => {
        this.setState({ openModal: true ,id:id});
        console.log(id);
    }
    closeModal = () => {
        this.setState({ openModal: false });
    }
    openBillModal = (id) => {
        this.setState({ openBillModal: true ,id:id});
        console.log(id);
    }
    openBillClosedModal= (id,totalCost,invoiceDate,invoiceNumber) => {
        this.setState({ openBillModal: true ,id:id,totalCost:totalCost,invoiceDate:invoiceDate,invoiceNumber:invoiceNumber});
    }
    closeBillModal = () => {
        this.setState({ openBillModal: false,invoiceNumber:'',invoiceDate:'',totalCost:'' });
    }
    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value }, () => {
            console.log(this.state);
        });
    }
    submitBill =(e)=> {
        let id =this.state.id;
        let bill={
            invoiceNumber:this.state.invoiceNumber,
            invoiceDate:this.state.invoiceDate,
            totalCost: this.state.totalCost
        };
        let activePage = this.state.currentPage;
        MaterialOrder.payBill(id,bill).then(res => {
            this.setState({ openBillModal: false });
            MaterialOrder.getAllPurchases(activePage).then(res => {
                res.data.purchaseOrders.map((item, idx)=>{
                    item.id = idx + 1 + activePage * 10;
                    return item;
                });
    
                return res;
    
            }).then(res => {
                this.setState({ purchaseOrders: res.data.purchaseOrders ||[]});
                const totalPages = res.data.totalPages;
                const totalItems = res.data.totalItems;
    
                this.setState({ totalPages: totalPages });
                this.setState({ totalItems: totalItems });
                console.log('Mount2');
                console.log(res.data);
            });
        });
    }
    render() {
        let { currentPage,invoiceNumber,invoiceDate,totalCost } = this.state;
        let that = this;
        
        const handleTableChange = (type, { page, sizePerPage }) => {
            this.handlePageChange (page);
        }

        const columns = [{
            dataField: 'id',
            text: '#'
          }, {
            dataField: 'purchaseOrderNumber',
            text: 'PO Number'
          }, {
            dataField: 'supplierName',
            text: 'Supplier Name'
          }, { 
            dataField: 'supplierPhoneNumber',
            text: 'Phone Number'
          }, {
            dataField: 'purchaseOrderStatus',
            text: 'Status'
          }, { 
            dataField: 'currency',
            text: 'Total Cost',
            formatter: function(cell, r){
                return <span>{r.currency} {r.totalCost}</span>;
            }
          }, {
            dataField: 'purchaseOrderName',
            text: 'PO Date'
          }, {
            dataField: 'Action',
            isDummyField: true,
            text: 'Action',
            formatter: function(cell, row){
                return (
                    <>
                    <div className='d-flex'>
                        {row.purchaseOrderStatus == 'OPEN' ? (
                            <>
                            <div className="gh-tooltip top" data-content="Bills">
                                <div
                                    className='mr-2 gh-status-Closed'
                                    onClick={() => {
                                        that.openBillModal(row.purchaseOrderId);
                                    }}
                                >
                                    <RiBillFill size={20}/>
                                </div>
                            </div>

                            <div className="gh-tooltip top" data-content="Edit">
                                <Link
                                    to={{
                                        pathname: '/editPurchaseOrder',
                                        state: { id: row.purchaseOrderId }
                                    }}
                                ><BiEdit size={20} className="gh-status-Closed" />
                                </Link>
                            </div>

                            <div className="gh-tooltip top" data-content="Delete">
                                <div
                                    className='ml-2 gh-status-Pending'
                                    onClick={() => {
                                        that.openModal(row.purchaseOrderId);
                                    }}
                                >
                                    <RiDeleteBin6Line size={20}/>
                                </div>
                            </div>
                            </>
                        ) : (
                            <div className="gh-tooltip top" data-content="Bills">
                                <div
                                    className='mr-2 gh-status-Pending'
                                    onClick={() => {
                                        that.openBillClosedModal(
                                            row.purchaseOrderId,
                                            row.totalCost,
                                            row.invoiceNumber,
                                            row.invoiceDate);
                                    }}
                                >
                                    <RiBillFill size={20} />
                                </div>
                            </div>
                        )}
                        
                    </div>                   
                    </>
                );
            }
          }     
        ];

        return (
            <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Purchase Orders</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            Material
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Purchase Orders</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >                  
                    <Row className="bg-white mx-auto">
                        <Col>             
                            
                            <div className='mt-5 mb-3 mx-2'>
                                <Link to='/addPurchaseOrder'>
                                    <Button className='mr-3' variant='gh-light'>
                                        Add Purchase Order
                                    </Button>
                                </Link>
                            </div>

                            <div className='d-flex justify-content-center mt-2 mb-2'>
 
                            <PaginationProvider
                                pagination={
                                    paginationFactory({
                                        custom: true,
                                        page: this.state.currentPage + 1,
                                        sizePerPage: 10,
                                        paginationSize: 10,
                                        alwaysShowAllBtns: true,
                                        totalSize: this.state.totalItems
                                    })
                                }
                                >
                                {
                                    ({
                                    paginationProps,
                                    paginationTableProps
                                    }) => (
                                    <div className='w-100'>
                                        <BootstrapTable
                                            remote
                                            keyField="id"
                                            data={ this.state.purchaseOrders }
                                            columns={ columns }                                            
                                            wrapperClasses="gh-table"
                                            onTableChange={ handleTableChange }
                                            { ...paginationTableProps }
                                        />
                                        
                                        <div className='d-flex justify-content-center'>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                        {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                                        
                                    </div>

                                    )
                                }
                            </PaginationProvider>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <NotificationContainer />
            </div>

                <Modal
                    className='textCenter'
                    show={this.state.openModal}
                    onHide={this.closeModal}
                >
                    <Modal.Header closeButton>Delete</Modal.Header>
                    <Modal.Body>Do you really want to delete?</Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-gh-light' onClick={this.closeModal} >
                            Close                                      
                        </button> 
                        <button className='btn btn-gh-success' onClick={this.delete} >
                            Delete
                        </button>
                    </Modal.Footer>
                </Modal> 
                
                <Modal
                    className='textCenter'
                    show={this.state.openBillModal}
                    onHide={this.closeBillModal}
                >
                    <Modal.Header closeButton>Billing Information</Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId='invoiceNumber'>
                                <Form.Label>Invoice Number </Form.Label>
                                <Form.Control
                                    type='text'
                                    name='invoiceNumber'
                                    onChange={this.changeHandler}
                                    value={invoiceNumber}
                                    placeholder='Invoice Number'
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group controlId='invoiceDate'>
                                <Form.Label>Invoice Date </Form.Label>
                                <Form.Control
                                    type='date'
                                    name='invoiceDate'
                                    onChange={this.changeHandler}
                                    value={invoiceDate}
                                    placeholder='Invoice Date'
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group controlId='totalCost'>
                                <Form.Label>Total Cost </Form.Label>
                                <Form.Control
                                    type='number'
                                    name='totalCost'
                                    onChange={this.changeHandler}
                                    value={totalCost}
                                    placeholder='Total Cost'
                                ></Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='btn btn-gh-light' onClick={this.closeBillModal} >
                            Close                                      
                        </button> 
                        <button className='btn btn-gh-success' onClick={this.submitBill} >
                            Submit
                        </button>
                    </Modal.Footer>
                </Modal> 

            </>

        );
    }
}

import React, { Component } from 'react';
import './home.css';
import {
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import { Chart } from 'react-google-charts';
import BootstrapTable from 'react-bootstrap-table-next';

import { FiUsers } from "react-icons/fi";
import { CiUser } from "react-icons/ci";
import { BiStore } from "react-icons/bi";
import DeliveryTruck from "../../../Images/icons/DeliveryTruck.svg";

import MasterDataService from '../../../Services/MasterDataService';

export default class HomeComponent extends Component {

    constructor (props) {

        super(props);

        this.state = {
            corporateCount: 0,
            customerCount: 0,
            supplierCount:0 ,
            storeCount: 0,
            monthlyStats: [],
            oderStatusStats: [],
            latestOrders: [],
            charData:[]
        };
		
        this.columns = [{
            dataField: 'customerName',
            text: 'Banner Name'
          }, {
            dataField: 'productName',
            text: 'Product Name'
          }, {
            dataField: 'storeName',
            text: 'Store Name'
          }, {
            dataField: 'orderStatus',
            text: 'Order Status',
            formatter: function(cell, row){
                return (
                    <b className={'gh-status-'+cell}>{cell}</b>
                );
            }
          }, {
            dataField: 'orderNumber',
            text: 'Order Number'
          }, {
            dataField: 'quantity',
            text: 'Quantity'         
        }];
    }

    componentDidMount () {
        MasterDataService.getAllDashboardData().then(res => {
            this.setState({ 
                monthlyStats: res.data.monthlyStats ||[],
                oderStatusStats: res.data.oderStatusStats ||[],
                latestOrders: res.data.latestOrders ||[],
                corporateCount:  res.data.corporateCount || 0,
                customerCount:  res.data.customerCount,
                supplierCount:  res.data.supplierCount,
                storeCount:  res.data.storeCount
            });
            const charData=[['month','Revenue']];
            res.data.monthlyStats.map((val)=>
                charData.push([val.month,val.revenue]));
            
            this.setState({charData:charData});
        });
    }
    render() {

        const {
            corporateCount,
            customerCount,
            supplierCount,
            storeCount,
            monthlyStats,
            oderStatusStats,
            charData,
            latestOrders
        } = this.state;

        return (
            <div className='gh-bgcolor gh-dashboard-bgimg' >

            <h4 className="container py-4 text-white font-weight-bold h4">Dashboard</h4>
            
            <div className="container">
                <div>
                    <div className="row mx-auto bg-white p-3" style={{ gap:"16px"}}>

                        <div className="col px-0 d-flex align-items-center gh-lightgreen" >
                            <div className="flex-grow-1 p-4 text-center" >
                                Corporates
                            </div>
                            <div className="text-center px-4 pt-2 gh-darkgreen" > 
                                <FiUsers size={25} />
                                <div className='h2 my-2 font-weight-bold'>{this.state.corporateCount}</div>
                            </div>
                        </div>

                        <div className="col px-0 d-flex align-items-center gh-lightgreen" >
                            <div className="flex-grow-1 p-4 text-center" >
                            Banners
                            </div>
                            <div className="text-center px-4 pt-2 gh-darkgreen" > 
                                <CiUser size={25} />
                                <div className='h2 my-2 font-weight-bold'>{customerCount}</div>
                            </div>
                        </div>

                        <div className="col px-0 d-flex align-items-center gh-lightgreen" >
                            <div className="flex-grow-1 p-4 text-center" >
                            Stores
                            </div>
                            <div className="text-center px-4 pt-2 gh-darkgreen" > 
                                <BiStore size={25} />
                                <div className='h2 my-2 font-weight-bold'>{storeCount}</div>
                            </div>
                        </div>

                        <div className="col px-0 d-flex align-items-center gh-lightgreen" >
                            <div className="flex-grow-1 p-4 text-center" >
                            Suppliers
                            </div>
                            <div className="text-center px-4 pt-2 gh-darkgreen" > 
                                <img
                                    src={DeliveryTruck} 
                                    width="25" 
                                ></img>
                                <div className='h2 my-2 font-weight-bold'>{supplierCount}</div>
                            </div>
                        </div>
                
                    </div>
                </div>
            </div>

            <Container className='homeComponent pb-5' >                  
                <Row className="bg-white mx-auto">
                    <Col>
                        <Row>
                            <Col lg={7} md={8} sm={6} xs={12}>
                                {monthlyStats.length === 0 ? (
                                    <div></div>
                                ) : (
                                    <>
                                    <h3 className='mt-5 ml-5 font-weight-bold h4'>Monthly Revenue Report</h3>
                                    <Chart
                                        width={'100%'}
                                        height={'380px'}
                                        chartType="PieChart"
                                        data={charData}
                                        options={{
                                            areaOpacity: 0.7,
                                            colors: ['#FEC74F', '#FC6D06', '#dc3545'],
                                            backgroundColor:{
                                                color: 'none',
                                                fill: 'none',
                                            },
                                            title: '',
                                            isStacked: true,
                                            animation:{
                                                easing: 'inAndOut',
                                            },
                                            hAxis: { 
                                                title: 'Months',
                                                gridlines: {
                                                    color: '#f3f3f3'
                                                },
                                                titleTextStyle: { color: 'black' } 
                                            },
                                            vAxis: { minValue: 0 },
                                            // For the legend to fit, we make the chart area smaller
                                            //chartArea: { width: '50%', height: '70%' },
                                            lineWidth: 25,
                                            is3D: true
                                        }}
                                    />
                                    </>
                                )}
                            </Col>
                            <Col lg={4} md={4} sm={6} xs={12} className='mt-5 '>
                                <div className='font-weight-bold border p-4 gh-heading-color'>
                                    <h3 className='h4 mb-3'>Production Updates</h3>
                                        {
                                            (oderStatusStats.length === 0) ? (
                                                <div>&nbsp;</div>
                                            ) : oderStatusStats.map((stat, idx) => (
                                                <div key={idx} className={'d-flex justify-content-between gh-status-'+stat.orderStatus} >
                                                    <div>{stat.orderStatus}</div>
                                                    <div>{stat.orderCount}</div>
                                                </div>
                                            ))
                                        }
                                </div>                               
                            </Col>
                        </Row> 
                        <h3 className='py-3 font-weight-bold h4 border-top-1'>Latest Orders</h3>                        
                        <Row>
                            <Col>
                                <BootstrapTable keyField='orderNumber' data={ latestOrders } columns={ this.columns } wrapperClasses="gh-table"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            </div>
        );
    }
}
import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import prouctService from '../../../Services/ProductService';
import './category.css';

export default class UpdateCategory extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            category:{
                categoryName: String
            }
        };
    }
      changeHandler = e => {
          let category = this.state.category;
          category[e.target.name] = e.target.value;
          this.setState({ category }, () => console.log(this.state.category));
      }
      componentDidMount () {
          let id = this.state.id;
          prouctService.getSingleCategory(id).then(res => {
              this.setState({ category: res.data||[] });
          });
      }
      updateCategory = e => {
          e.preventDefault();
          let id= this.state.id;
          let category = this.state.category;
          console.log(category);
          prouctService
              .updateCategory(id,category)
              .then(res => {
                  console.log('success');
                  console.log(res.data);
                  // eslint-disable-next-line react/prop-types
                  this.props.history.push('/category');
              })
              .catch(res => {
                  console.log('failure');
                  console.log(res.data);
              });
      }
      render() {

          let { categoryName } = this.state.category;
          
          return (
            <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Season</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            Configuration
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/category" }} linkAs={Link}>
                            Season
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Update Season</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >
                    <Card>
                        <Card.Header className='px-5 gh-card-header'>
                            <Card.Title className='card-title mb-0 text-left'>
                                <h4 className="mb-0">Update Season</h4>
                            </Card.Title>
                        </Card.Header>
                        <Card.Body className='p-5'>

                        <Form>
                              <Row>
                                  <Col lg={4} md={6} sm={6} xs={12}>
                                      <Form.Group controlId='categoryName'>
                                          <Form.Label>Season Name</Form.Label>
                                          <Form.Control
                                              type='text'
                                              name='categoryName'
                                              onChange={this.changeHandler}
                                              value={categoryName}
                                              placeholder='Season Name'
                                          ></Form.Control>
                                      </Form.Group>
                                  </Col>
                              </Row>
                              <Row className=''>
                                <Col className='text-right'>
                                <Link to='/category'>
                                      <Button className='mt-3' variant='gh-light'>
                                        Back
                                      </Button>
                                  </Link>
                                  <Button
                                      className='mt-3 ml-3'
                                      variant='gh-success'
                                      type='submit'
                                      onClick={this.updateCategory}
                                  >
                                    Submit
                                  </Button>
                                </Col>
                              </Row>
                          </Form>
                        </Card.Body>
                    </Card>
                </Container>

              </div>
            </>
          );
      }
}

import React from 'react';
import './footer.css';

function FooterComponet () {
    return (
        <div>
            <link
                rel='stylesheet'
                href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css'/>
            <link
                rel='stylesheet'
                href='https://cdnjs.cloudflare.com/ajax/libs/fork-awesome/1.1.7/css/fork-awesome.min.css'/>
            <script src='https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js'></script>
            <script src='https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/js/bootstrap.min.js'></script>
            <div className='mt-5 pt-5 pb-5 footer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5 col-xs-12 about-company'>
                            <h2>BLITZER</h2>
                            <p className='pr-5 text-white-50'>
              We are committed to providing quality products and exceptional service from corporate to independent garden centers across Ontario.{' '}
                            </p>
                            {/* <p>
                                <a href='https://www.facebook.com/ScharringaGreenhouses/'>
                                    <i className='fa fa-facebook-square mr-3'></i> 
                                </a>
                                <a href='https://ca.linkedin.com/in/marsha-lobbezoo-929a6783'>
                                    <i className='fa fa-linkedin-square'></i>
                                </a>
                            </p> */}
                        </div>
                        <div className='col-lg-3 col-xs-12 links'>
                            {/* <h4 className='mt-lg-0 mt-sm-3'>Links</h4>
                            <ul className='m-0 p-0'>
                                <li>
                  - <a href='https://scharringagreenhouses.com/'>Scharringa</a>
                                </li>
                            </ul> */}
                        </div>
                        <div className='col-lg-4 col-xs-12 location'>
                            <h4 className='mt-lg-0 mt-sm-4'>Location</h4>
                            <p>379 Concession 4 West, Waterdown, ON L8B 1J4</p>
                            <p className='mb-0'>
                                <i className='fa fa-phone mr-3'></i>(905) 689-5910
                            </p>
                            {/* <p>
                                <i className='fa fa-envelope-o mr-3'></i>marsha@scharringagreenhouses.com
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterComponet;

import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './area.css';
import ZoneService from '../../../Services/ZoneService';

export default class UpdateArea extends Component {
    constructor (props) {
        super(props);
        this.state = {
            // eslint-disable-next-line react/prop-types
            id: props.location.state.id,
            area: {
                areaName: String
            }
        };
    }
  changeHandler = e => {
      let area = this.state.area;
      area[e.target.name] = e.target.value;
      this.setState({ area }, () => console.log(this.state.area));
  }
  componentDidMount () {
      let id = this.state.id;
      ZoneService.getSingleArea(id).then(res => {
          this.setState({ area: res.data||[] });
      });
  }
  updateArea = e => {
      e.preventDefault();
      let id = this.state.id;
      let area = this.state.area;
      console.log(area);
      ZoneService.updateArea(id, area)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/area');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let { areaName } = this.state.area;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
        <>
                <div className='gh-bgcolor gh-dashboard-bgimg' >
                    
                    <div className='container d-flex align-items-center justify-content-between' >

                        <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Area</h4>

                        <Breadcrumb className='gh-breadcrumb'>
                            <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                                <FaHome />
                            </Breadcrumb.Item>
                            <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                                Configuration
                            </Breadcrumb.Item>
                            <Breadcrumb.Item linkProps={{ to: "/area" }} linkAs={Link}>
                                Area
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Update Area</Breadcrumb.Item>
                        </Breadcrumb>

                    </div>

                    <Container className='homeComponent pb-5' >
                        <Card>
                            <Card.Header className='px-5 gh-card-header'>
                                <Card.Title className='card-title mb-0 text-left'>
                                    <h4 className="mb-0">Update Area</h4>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='p-5'>

                            <Form>
                                <Row>
                                    <Col lg={4} md={6} sm={6} xs={12}>
                                        <Form.Group controlId='areaName'>
                                            <Form.Label>Area Name</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='areaName'
                                                onChange={this.changeHandler}
                                                value={areaName}
                                                placeholder='Area Name'
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className=''>
                                    <Col className='text-right'>
                                    <Link to='/area'>
                                        <Button className='mt-3' variant='gh-light'>
                                            Back
                                        </Button>
                                    </Link>
                                    <Button
                                        className='mt-3 ml-3'
                                        variant='gh-success'
                                        type='submit'
                                        onClick={this.updateArea}
                                    >
                                        Submit
                                    </Button>
                                    </Col>
                                </Row>
                            </Form>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>
            </>
      );
  }
}

import axios from "axios";
import AuthorizationService from "../Services/AuthenticationService";
// const API_URL = 'http://localhost:8082';
// const API_URL = 'http://erpde-Publi-WSTWK7GSVILE-1821530495.ap-south-1.elb.amazonaws.com';

// const API_URL = "http://35.200.212.170"; // /userdata/api/
const API_URL = process.env.REACT_APP_BASE_URL;

class UserService {
  getAllUser(pageNumber) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/userdata/api/users`, {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        pageNo: `${pageNumber}`,
        pageSize: 10,
      },
    });
  }
  getAllRoles() {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/userdata/api/roles`, {
      headers: {
        Authorization: `${token}`,
      },
    });
  }
  enable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/userdata/api/users/${id}/enable`, data, {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  disable(id) {
    let data = "";
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/userdata/api/users/${id}/disable`, data, {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  getSingleUser(id) {
    let token = AuthorizationService.getToken();
    return axios.get(`${API_URL}/userdata/api/users/${id}`, {
      headers: {
        Authorization: `${token}`,
        "Content-Type": "application/json",
      },
    });
  }
  updateUser(id, data) {
    let token = AuthorizationService.getToken();
    return axios.put(`${API_URL}/userdata/api/users/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
  addUser(data) {
    let token = AuthorizationService.getToken();
    console.log(data);
    return axios.post(`${API_URL}/userdata/api/users`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    });
  }
}
export default new UserService();

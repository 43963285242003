import React, { Component } from "react";
import { Row, Table, Col, Card, Container, Breadcrumb,Form } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./invoice.css";
import CustomerOrderService from "../../Services/CustomerOrderService";
import { BsBoxArrowInDown } from "react-icons/bs";
const loggedIn = sessionStorage.getItem('loggedInUser');
import AuthenticationService from '../../Services/AuthenticationService';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import { NotificationContainer, NotificationManager } from 'react-notifications';

export default class InvoiceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      corporates: [],
      corporateId: Number,
      invoices: [],
    };
  }
  componentDidMount() {
    // CustomerOrderService.getAllInvoices().then((res) => {
    //   this.setState({ invoices: res.data || [] });
    // });
    let userRole = AuthenticationService.getRole();
    if(userRole != 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer'){
      CustomerOrderService.getAllInvoices().then((res) => {
        this.setState({ invoices: res.data || [] });
      });
    }else{
      let demoRes = {
        data:{
          corporates:[
            {
              "corporateId": 1,
              "corporateName": "DEMO GREENHOUSE 1"
            },
            {
              "corporateId": 2,
              "corporateName": "DEMO GREENHOUSE 2"
            },
            {
              "corporateId": 3,
              "corporateName": "DEMO GREENHOUSE 3"
            }
          ]
        }
      }
      this.setState({ corporates: demoRes.data.corporates || [] });
    }
  }

  changeHandler = (e) => {
    let id = e.target.value;
    switch (e.target.name) {
      case "corporateId":
        console.log(id);
        CustomerOrderService.getAllInvoicesV1(id).then((res) => {
          this.setState({ invoices: res.data || [] });
        });
        break;
      default:
        break;
    }
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  downloadFile = (id) => {
    CustomerOrderService.downloadInvoice(id).then((response) => {
      const url = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(url);
      link.download = "orderInvoice.pdf";
      link.click();
      return response.data;
    });
  };

  render() {

    let that = this;
    let userRole = AuthenticationService.getRole();
    let {
      corporates,
      corporateId
    } = this.state;

    const ColoredLine = ({ color }) => (
      <hr
        style={{
          color: color,
          backgroundColor: color,
          marginTop: 0,
          height: 1,
        }}
      />
    );

    const handleTableChange = (type, { page, sizePerPage }) => {
      this.handlePageChange (page);
    }

    const columns = [{
      dataField: 'invoiceId',
      text: '#',
      sort: true
    }, {
      dataField: 'invoiceNumber',
      text: 'Invoice Number'
    }, { 
      dataField: 'customerName',
      text: 'Banner Name'
    }, {
      dataField: 'storeName',
      text: 'Store Name'
    }, {
      dataField: 'totalItems',
      text: 'Total Items', 
    }, {
      dataField: 'tax',
      text: 'HST%'
    }, {
      dataField: 'subtotalCost',
      text: 'Cost',
      isDummyField: true,
      formatter: function(cell, row){
        return <span>{row.currency} {row.subtotalCost}</span>;
      }
    },  {
      dataField: 'taxCost',
      text: 'HST',
      isDummyField: true,
      formatter: function(cell, row){
        return <span>{row.currency} {row.taxCost}</span>;
      }
    }, {
      dataField: 'totalCost',
      text: 'Total Cost',
      isDummyField: true,
      formatter: function(cell, row){
        return <span>{row.currency} {row.totalCost}</span>;
      }
    }, {
      dataField: 'invoiceDate',
      text: 'Invoice Date'
    }, {
      dataField: 'Download',
      isDummyField: true,
      text: 'Download',
      formatter: function(cell, row){
          return (
              <>
              <div className='d-flex'>
                  <div className="gh-tooltip top" data-content="Download">
                      <BsBoxArrowInDown
                        size={20} className="gh-status-Closed"
                        onClick={() => {
                          that.downloadFile(row.invoiceId);
                        }}
                      />
                  </div> 
              </div>
              </>
          );
      }
    }     
  ];

    return (
      <>
            <div className='gh-bgcolor gh-dashboard-bgimg' >
                
                <div className='container d-flex align-items-center justify-content-between' >

                    <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Invoice</h4>

                    <Breadcrumb className='gh-breadcrumb'>
                        <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                            <FaHome />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkProps={{ to: "/order" }} linkAs={Link} >
                          Order
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Invoice</Breadcrumb.Item>
                    </Breadcrumb>

                </div>

                <Container className='homeComponent pb-5' >                  
                    <Row className="bg-white mx-auto ">
                        <Col className="mt-2">

                            {userRole == 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer' ? ( 
                                <Form.Group controlId="corporateId">
                                  <Form.Label>Greenhouse</Form.Label>
                                  <Form.Control
                                    as="select"
                                    name="corporateId"
                                    onChange={this.changeHandler}
                                    value={corporateId}
                                  >
                                    <option>Select Greenhouse</option>
                                    {corporates.map((corporate, idx) => (
                                      <option key={idx} value={corporate.corporateId}>
                                        {corporate.corporateName}
                                      </option>
                                    ))}
                                  </Form.Control>
                                </Form.Group>
                            ) : ('')}


                            <div className='d-flex justify-content-center mt-2 mb-2'>
 
                            <PaginationProvider
                                pagination={
                                    paginationFactory({
                                        custom: true,
                                        page: this.state.currentPage + 1,
                                        sizePerPage: 10,
                                        paginationSize: 10,
                                        alwaysShowAllBtns: true,
                                        totalSize: this.state.invoices.length
                                    })
                                }
                                >
                                {
                                    ({
                                    paginationProps,
                                    paginationTableProps
                                    }) => (
                                    <div className='w-100'>
                                        <BootstrapTable
                                            remote
                                            keyField="invoiceId"
                                            data={ this.state.invoices }
                                            columns={ columns }                                            
                                            wrapperClasses="gh-table"
                                            onTableChange={ handleTableChange }
                                            { ...paginationTableProps }
                                        />
                                        
                                        <div className='d-none justify-content-center'>
                                            <PaginationListStandalone
                                                { ...paginationProps }
                                            />
                                        </div>
                                        {/* <pre>{JSON.stringify(this.state.invoices.length, null, 2) }</pre> */}
                                        
                                    </div>

                                    )
                                }
                            </PaginationProvider>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <NotificationContainer />
            </div>

      </>
    );
  }
}

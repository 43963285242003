import React, { Component } from 'react';
import {
    Card,
    Form,
    Container,
    Breadcrumb,
    Button,
    Row,
    Col
} from 'react-bootstrap';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import customerService from '../../../Services/CustomerService';
import masterDataService from '../../../Services/MasterDataService';
import './customer.css';

export default class AddCustomer extends Component {
    constructor (props) {
        super(props);
        this.state = {
            countries: [],
            currencies: [],
            corporateName: String,
            customerName: String,
            emailAddress: String,
            phoneNumber: String,
            currency: String,
            address: {
                address1: String,
                address2: String,
                city: String,
                region: String,
                province: String,
                country: String
            },
            formErrors: {
                corporateName: '',
                customerName: ''
            },
            errorStatus: false
        };
    }
    componentDidMount () {
        console.log('Mount');
        masterDataService.getAllCountries().then(res => {
            this.setState({ countries: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
        masterDataService.getAllCurrencies().then(res => {
            this.setState({ currencies: res.data ||[]});
            console.log('Mount2');
            console.log(res.data);
        });
    }
    onChange (e) {
        console.log('inside if');
        let address = this.state.address;
        console.log(e.target.name);
        address[e.target.name] = e.target.value;
        this.setState({ address }, () => console.log(this.state.address));
    }

  changeHandler = e => {
      let formErrors = { ...this.state.formErrors };
      //Switch method for tackling particular formError
      switch (e.target.name) {
      case 'corporateName':
          formErrors.id =
          e.target.value.length < 3 ? 'minimum 3 characterss required' : '';
          break;
      case 'customerName':
          formErrors.name =
          e.target.value.length < 3 ? 'minimum 3 characterss required' : '';
          break;
      default:
          break;
      }
      this.setState({ formErrors, [e.target.name]: e.target.value }, () => {
          console.log(this.state);
      });
  }

  submitCustomer = e => {
      e.preventDefault();
      let customer = {
          corporateName: this.state.corporateName,
          customerName: this.state.customerName,
          emailAddress: this.state.emailAddress,
          phoneNumber: this.state.phoneNumber,
          currency: this.state.currency,
          address: this.state.address
      };

      let formData = new FormData();
      formData.append('customer', JSON.stringify(customer));

      console.log(JSON.stringify(customer));
      console.log('Inside form submission');
      customerService
          .addCustomer(JSON.stringify(customer))
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/customer');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      let {
          countries,
          currencies,
          corporateName,
          customerName,
          emailAddress,
          phoneNumber,
          currency,
          address
      } = this.state;
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      return (
        <>
        <div className='gh-bgcolor gh-dashboard-bgimg' >
            
            <div className='container d-flex align-items-center justify-content-between' >

                <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Banner</h4>

                <Breadcrumb className='gh-breadcrumb'>
                    <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                        <FaHome />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                        Profiles
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkProps={{ to: "/Customer" }} linkAs={Link}>
                        Banner
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Add Banner</Breadcrumb.Item>
                </Breadcrumb>

            </div>

            <Container className='homeComponent pb-5' >                  
                              
                        <Card>
                            <Card.Header className='px-5 gh-card-header'>
                                <Card.Title className='card-title mb-0 text-left'>
                                    <h4 className="mb-0">Add Banner</h4>
                                </Card.Title>
                            </Card.Header>
                            <Card.Body className='p-5'>
                                <Form>
                                    <Row>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='corporateName'>
                                                <Form.Label>Corporate Name </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='corporateName'
                                                    onChange={this.changeHandler}
                                                    value={corporateName}
                                                    placeholder='Corporate Name'
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='customerName'>
                                                <Form.Label>Banner Name </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='customerName'
                                                    onChange={this.changeHandler}
                                                    value={customerName}
                                                    placeholder='Banner Name'
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='emailAddress'>
                                                <Form.Label>Email Address </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='emailAddress'
                                                    onChange={this.changeHandler}
                                                    value={emailAddress}
                                                    placeholder='Email Address'
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='phoneNumber'>
                                                <Form.Label>Phone Number</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='phoneNumber'
                                                    onChange={this.changeHandler}
                                                    value={phoneNumber}
                                                    placeholder='Phone Number'
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Group controlId='address.address1'>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    rows='4'
                                                    cols='4'
                                                    name='address1'
                                                    onChange={e => this.onChange(e)}
                                                    value={address.address1}
                                                    placeholder='Enter address ...'
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='address.country'>
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    name='country'
                                                    onChange={e => this.onChange(e)}
                                                    value={address.country}
                                                >
                                                    <option>Select Country</option>
                                                    {countries.map((country, idx) => (
                                                        <option key={idx} value={country.value}>
                                                            {country.value}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='address.city'>
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='city'
                                                    onChange={e => this.onChange(e)}
                                                    value={address.city}
                                                    placeholder='City Name'
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='address.province'>
                                                <Form.Label>Province</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='province'
                                                    onChange={e => this.onChange(e)}
                                                    value={address.province}
                                                    placeholder='Province Name'
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='address.region'>
                                                <Form.Label>Region</Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    name='region'
                                                    onChange={e => this.onChange(e)}
                                                    value={address.region}
                                                    placeholder='Region Name'
                                                ></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={6} xs={12}>
                                            <Form.Group controlId='currency'>
                                                <Form.Label>Currency</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    name='currency'
                                                    onChange={this.changeHandler}
                                                    value={currency}
                                                > <option>Select Currency</option>
                                                    {currencies.map((currency, idx) => (
                                                        <option key={idx} value={currency.currencyName}>
                                                            {currency.currencyName}
                                                        </option>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='text-right'>
                                        <Link to='/customer'>
                                            <Button className='mt-3' variant='gh-light'>
                                            Back
                                            </Button>
                                        </Link>
                                        <Button
                                            className='mt-3 ml-3'
                                            variant='gh-success'
                                            type='submit'
                                            onClick={this.submitCustomer}
                                        >
                                            Submit
                                        </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card.Body>
                        </Card>
            </Container>

          </div> 
        </>
      );
  }
}

import React, { Component } from 'react';
import {
    Row,
    Table,
    Card,
    Container
} from 'react-bootstrap';
import MaterialOrder from '../../Services/MaterialOrder';
import Pagination from 'react-js-pagination';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';


export default class ClosedOrder extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stores: [],
            currentPage: 0,
            totalPages: null,
            totalItems: null
        };
    }
    handlePageChange (pageNumber) {
        let page = pageNumber - 1;
        this.setState({ currentPage: page });
        MaterialOrder.getAllClosedStore(page).then(res => {
            this.setState({ stores: res.data.storeOrders ||[]});
            console.log('Mount2 closed');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });
    }
    
    componentDidMount () {
        console.log('Mount');
        let activePage = this.state.currentPage;
        MaterialOrder.getAllClosedStore(activePage).then(res => {
            this.setState({ stores: res.data.storeOrders ||[]});
            console.log('Mount2');
            console.log(res.data);
            const totalPages = res.data.totalPages;
            const totalItems = res.data.totalItems;

            this.setState({ totalPages: totalPages });
            this.setState({ totalItems: totalItems });
        });

    }
    
    render() {

        const handleTableChange = (type, { page, sizePerPage }) => {
            this.handlePageChange (page);
        }
        
        const columns = [{
            dataField: 'materialName',
            text: 'Material Name'
          }, {
            dataField: 'orderQuantity',
            text: 'Requested Quantity'
          }, {
            dataField: 'suppliedQuantity',
            text: 'Provided Quantity'
          }, {
            dataField: 'operatorName',
            text: 'Operator Name', 
          }, {
            dataField: 'requestedDate',
            text: 'Request Date'
          }, {
            dataField: 'closedDate',
            text: 'Close Date'
          }, {
            dataField: 'remark',
            text: 'Remark'
          }, {
            dataField: 'isRejected',
            text: 'Is Rejected',
            formatter: function(cell, row) {
                return <>{cell?('Yes'):('No')}</>;
            }
          }   
        ];

        return (
            <Container className='mainStore p-0'>
                <div>
                    <PaginationProvider
                        pagination={
                            paginationFactory({
                                custom: true,
                                page: this.state.currentPage + 1,
                                sizePerPage: 10,
                                paginationSize: 10,
                                alwaysShowAllBtns: true,
                                totalSize: this.state.totalItems
                            })
                        }
                        >
                        {
                            ({
                            paginationProps,
                            paginationTableProps
                            }) => (
                            <div className='w-100'>
                                <BootstrapTable
                                    remote
                                    keyField="id"
                                    data={ this.state.stores }
                                    columns={ columns }                                            
                                    wrapperClasses="gh-table"
                                    onTableChange={ handleTableChange }
                                    { ...paginationTableProps }
                                />
                                
                                <div className='d-flex justify-content-center'>
                                    <PaginationListStandalone
                                        { ...paginationProps }
                                    />
                                </div>
                                {/* <pre>{JSON.stringify(paginationProps, null, 2) }</pre> */}
                                
                            </div>
                            )
                        }
                    </PaginationProvider>
                </div>
            </Container>
        );
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import axios from 'axios';
import AuthenticationService from './Services/AuthenticationService';
import { NotificationContainer, NotificationManager } from 'react-notifications';

// For GET requests
axios.interceptors.request.use(
    (req) => {
        // Add configurations here
        console.log('request send');
        console.log(req);
        return req;
    },
    (err) => {
        console.log('request error');
        console.log(err);
        return Promise.reject(err);
    }
);
 
// For POST requests
axios.interceptors.response.use(
    (res) => {
        console.log('response');
        console.log(res);
        // Add configurations here
        if (res.status === 201) {
            console.log('Posted Successfully');
        }
        return res;
    },
    (err) => {
        console.log('response error');
        console.log(err.response);
        let errResponse='';
        if (!err.response) {
            console.log('network error check by Tarun');
            errResponse=err;
        }else if(err.response.status==401||err.response.status==403){
            console.log('Logout Completed');
            AuthenticationService.logout();
            errResponse=err.response.status+' Session Expired';
        }else{
            errResponse=err.response.data;
        }
        NotificationManager.error(errResponse+'', 'Error');
        return Promise.reject(err);
    }
);

ReactDOM.render(
    <React.StrictMode>
        <App />
        <NotificationContainer />
    </React.StrictMode>,
    document.getElementById('root')
);
import React, { Component } from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
export default class Error404 extends Component {
    render() {
        return (
            <Container className="mainCustomer" >
                <Row className=" display-4 w-100 text-center d-block">
                    <Col>Where are you going? <span className="text-danger"> Its a 404!!!</span></Col>
                    <Col><Link className="btn btn-outline-dark" to="/" >Take me Back!</Link></Col>
                </Row>
            </Container>
        );
    }
}

import React, { Component } from 'react';
import AuthenticationService from '../../Services/AuthenticationService';
import Footer from '../Site/Footer/FooterComponet';
import { Row, Col, Form, Card, Container } from 'react-bootstrap';
import jwt from 'jwt-decode';
import * as Io from 'react-icons/io';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'; 

import logo from '../../Images/logo-1.png';

import './login.css'
import './modal.css'
class LoginComponent extends Component {
    constructor (props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            hasLoginFailed: false,
            showSuccessMessage: false,
            accessToken: '',
            role: ''
        };
        this.handleChange = this.handleChange.bind(this);
        this.loginClicked = this.loginClicked.bind(this);
    }

    openModal = () => {
        this.setState({ openModal: true });
    }
  
    closeModal = () => {
        this.setState({ openModal: false });
    }

    handleChange (event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    loginClicked () {
        AuthenticationService.executeJwtAuthenticationService(
            this.state.username,
            this.state.password
        )
            .then(response => {
                var token = response.data.jwt;
                this.setState({ accessToken: token });
                const user = jwt(token);
                console.log(user)
                this.setState({ role: user.authorities });
                console.log(user);
                AuthenticationService.setRole(user.authorities);
                AuthenticationService.registerSuccessfulLoginForJwt(
                    user.username,
                    response.data.jwt
                );
                sessionStorage.setItem('loggedInUser',user.authorities)
                sessionStorage.setItem('userid',user.userid)
                switch(user.authorities){
                case 'ROLE_Administrator':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/home');
                    break;
                case 'ROLE_Stores':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/storeInventory');
                    break;
                case 'ROLE_Operator':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/shop');
                    break;
                case 'ROLE_Buyer,ROLE_Buyer,ROLE_Buyer':
                    // eslint-disable-next-line react/prop-types
                    this.props.history.push('/home');
                    break;
                }
                
            })
            .catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                this.setState({ showSuccessMessage: false });
                this.setState({ hasLoginFailed: true });
            });
    }

    render () {
        return (
            <>
                <Modal show={this.state.openModal} 
                size="xl"
                style={{height:"100vh"}}
                className="modalScreen"
                onHide={this.closeModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Modal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <iframe style={{width:'100%',height:'100%'}} id="iframe"
                        src="https://www.etechtracker.com/trackingLink.htm?token=5037%2300000020231103%2300000020240331">
                    </iframe>
                    </Modal.Body>
                </Modal>
                <Container className='themed-container login-container' fluid={true}  >
                                             
                            <img src={logo} width="300px" />
                                     
                            <Card
                                className='p-4 mx-4'
                                bg='white'
                                style={{ width: '500px',  }}
                            > 
                                <Card.Body>
                                    <h3 className='mb-5' >Login</h3>

                                    <Form>
                                        {this.state.hasLoginFailed && (
                                            <div className='alert alert-warning mb-4'>
                                                Invalid Credentials
                                            </div>
                                        )}
                                        <Form.Group as={Row} className="mb-4" >
                                            <Form.Label column sm="4">User Name</Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                type='text'
                                                name='username'
                                                defaultValue={this.state.username}
                                                onChange={this.handleChange}
                                                 />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} className="mb-4" >
                                            <Form.Label column sm="4">Password</Form.Label>
                                            <Col sm="8">
                                                <Form.Control
                                                type='password'
                                                name='password'
                                                defaultValue={this.state.password}
                                                onChange={this.handleChange}
                                                 />
                                            </Col>
                                        </Form.Group>

                                    </Form> 

                                        <button
                                            className='btn btn-gh-success mt-2 text-white float-right'
                                            onClick={this.loginClicked}
                                        >LOG IN</button>


                                </Card.Body>
                            </Card>
                         
                </Container>
            </>
        );
    }
}

export default LoginComponent;

import React, { Component } from 'react';
import {
    Row,
    Form,
    Col,
    Button,
    Card,
    Container,
    Breadcrumb
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';
import './rack.css';
import RackService from '../../../Services/RackService';

export default class AddRackExcel extends Component {
    constructor (props) {
        super(props);
        this.state = {
            file: ''
        };
    }

    changeHandle (e) {
        console.log('inside if');
        this.setState({ file: e.target.files[0] });
    }

  submitExcel = e => {
      e.preventDefault();
      let formData = new FormData();
      formData.append('file', this.state.file);
      formData.append('userId', '1');
      console.log('data');
      console.log(formData);
      RackService.addRackExcel(formData)
          .then(res => {
              console.log('success');
              console.log(res.data);
              // eslint-disable-next-line react/prop-types
              this.props.history.push('/rack');
          })
          .catch(res => {
              console.log('failure');
              console.log(res.data);
          });
  }
  render () {
      const ColoredLine = ({ color }) => (
          <hr
              style={{
                  color: color,
                  backgroundColor: color,
                  marginTop: 0,
                  height: 1
              }}
          />
      );
      let { file } = this.state;
      return (
        <div className='gh-bgcolor gh-dashboard-bgimg' >
            
            <div className='container d-flex align-items-center justify-content-between' >

                <h4 className="py-4 text-white font-weight-bold h4 d-inline-block">Rack</h4>

                <Breadcrumb className='gh-breadcrumb'>
                    <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                        <FaHome />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkProps={{ to: "/home" }} linkAs={Link} >
                        Configuration
                    </Breadcrumb.Item>
                    <Breadcrumb.Item linkProps={{ to: "/rack" }} linkAs={Link}>
                        Rack
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Add Rack Excel</Breadcrumb.Item>
                </Breadcrumb>

            </div>

            <Container className='homeComponent pb-5' >   
                <Card>
                    <Card.Header className='px-5 gh-card-header'>
                        <Card.Title className='card-title mb-0 text-left'>
                            <h4 className="mb-0">Add Rack Excel</h4>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body className='p-5'>
                        <Form>
                            <Form.Group>
                                <Form.File
                                    className='position-relative'
                                    required
                                    name='file'
                                    label='Please choose excel to upload'
                                    vaulue={file}
                                    onChange={e => this.changeHandle(e)}
                                    feedbackTooltip
                                />
                            </Form.Group>
                            <Row className=''>
                                <Col>
                                <Link to='/rack'>
                                    <Button className='mt-3' variant='gh-light'>
                                    Back
                                    </Button>
                                </Link>
                                <Button
                                    className='mt-3 ml-3'
                                    variant='gh-success'
                                    type='submit'
                                    onClick={this.submitExcel}
                                >
                                Submit
                                </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
      );
  }
}
